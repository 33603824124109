/**
 * Based on the video.js translation licensed under the following license
 *
 * Copyright Brightcove, Inc.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

export default {
    "Play": "Wiedergabe",
    "Pause": "Pause",
    "Replay": "Erneut abspielen",
    "Current Time": "Aktueller Zeitpunkt",
    "Duration": "Dauer",
    "Remaining Time": "Verbleibende Zeit",
    "Stream Type": "Streamtyp",
    "LIVE": "LIVE",
    "Loaded": "Geladen",
    "Progress": "Fortschritt",
    "Fullscreen": "Vollbild",
    "Non-Fullscreen": "Vollbildmodus beenden",
    "Mute": "Ton aus",
    "Unmute": "Ton ein",
    "Playback Rate": "Wiedergabegeschwindigkeit",
    "Subtitles": "Untertitel",
    "subtitles off": "Untertitel aus",
    "Captions": "Untertitel",
    "captions off": "Untertitel aus",
    "Chapters": "Kapitel",
    "You aborted the media playback": "Sie haben die Videowiedergabe abgebrochen.",
    "A network error caused the media download to fail part-way.": "Der Videodownload ist aufgrund eines Netzwerkfehlers fehlgeschlagen.",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Das Video konnte nicht geladen werden, da entweder ein Server- oder Netzwerkfehler auftrat oder das Format nicht unterstützt wird.",
    "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Die Videowiedergabe wurde entweder wegen eines Problems mit einem beschädigten Video oder wegen verwendeten Funktionen, die vom Browser nicht unterstützt werden, abgebrochen.",
    "No compatible source was found for this media.": "Für dieses Video wurde keine kompatible Quelle gefunden.",
    "Play Video": "Video abspielen",
    "Close": "Schließen",
    "Modal Window": "Modales Fenster",
    "This is a modal window": "Dies ist ein modales Fenster",
    "This modal can be closed by pressing the Escape key or activating the close button.": "Durch Drücken der Esc-Taste bzw. Betätigung der Schaltfläche \"Schließen\" wird dieses modale Fenster geschlossen.",
    ", opens captions settings dialog": ", öffnet Einstellungen für Untertitel",
    ", opens subtitles settings dialog": ", öffnet Einstellungen für Untertitel",
    ", selected": ", ausgewählt",
    "captions settings": "UT. Einstellungen",
    "subtitles settings": "UT. Einstellungen",
    "descriptions settings": "Einstellungen für Beschreibungen",
    "Close Modal Dialog": "Modales Fenster schließen",
    "Descriptions": "Beschreibungen",
    "descriptions off": "Beschreibungen aus",
    "The media is encrypted and we do not have the keys to decrypt it.": "Die Entschlüsselungsschlüssel für den verschlüsselten Medieninhalt sind nicht verfügbar.",
    ", opens descriptions settings dialog": ", öffnet Einstellungen für Beschreibungen",
    "Audio Track": "Tonspur",
    "Text": "Schrift",
    "White": "Weiß",
    "Black": "Schwarz",
    "Red": "Rot",
    "Green": "Grün",
    "Blue": "Blau",
    "Yellow": "Gelb",
    "Magenta": "Magenta",
    "Cyan": "Türkis",
    "Background": "Hintergrund",
    "Window": "Fenster",
    "Transparent": "Durchsichtig",
    "Semi-Transparent": "Halbdurchsichtig",
    "Opaque": "Undurchsichtig",
    "Font Size": "Schriftgröße",
    "Text Edge Style": "Textkantenstil",
    "None": "Kein",
    "Raised": "Erhoben",
    "Depressed": "Gedrückt",
    "Uniform": "Uniform",
    "Dropshadow": "Schlagschatten",
    "Font Family": "Schriftfamilie",
    "Proportional Sans-Serif": "Proportionale Sans-Serif",
    "Monospace Sans-Serif": "Monospace Sans-Serif",
    "Proportional Serif": "Proportionale Serif",
    "Monospace Serif": "Monospace Serif",
    "Casual": "Zwanglos",
    "Script": "Schreibschrift",
    "Small Caps": "Small-Caps",
    "Reset": "Zurücksetzen",
    "restore all settings to the default values": "Alle Einstellungen auf die Standardwerte zurücksetzen",
    "Done": "Fertig",
    "Caption Settings Dialog": "Einstellungsdialog für Untertitel",
    "Beginning of dialog window. Escape will cancel and close the window.": "Anfang des Dialogfensters. Esc bricht ab und schließt das Fenster.",
    "End of dialog window.": "Ende des Dialogfensters.",
    "Audio Player": "Audio-Player",
    "Video Player": "Video-Player",
    "Progress Bar": "Fortschrittsbalken",
    "progress bar timing: currentTime={1} duration={2}": "{1} von {2}",
    "Volume Level": "Lautstärke",
    "{1} is loading.": "{1} wird geladen.",
    "Seek to live, currently behind live": "Zur Live-Übertragung wechseln. Aktuell wird es nicht live abgespielt.",
    "Seek to live, currently playing live": "Zur Live-Übertragung wechseln. Es wird aktuell live abgespielt.",
    "Exit Picture-in-Picture": "Bild-im-Bild-Modus beenden",
    "Picture-in-Picture": "Bild-im-Bild-Modus"
}
